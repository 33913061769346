import { Button, Empty } from "antd";
import React, { useState } from "react";
import { IExamPaperQuestion } from "src/services/exam/exam.model";
import ModalComponent from "../Modal";

export const AnswersheetModal = (props: {
  answerSheetData: IExamPaperQuestion[];
}) => {
  const [showModel, setShowModel] = useState(false);
  const [descriptionData, setDescriptionData] = useState("");
  const { answerSheetData } = props;

  const toLink = (content: string) => {
    return content && content.startsWith("http") ? (
      <a href={content} target="_blank" rel="noopener noreferrer">
        {content}
      </a>
    ) : (
      content
    );
  };

  return (
    <>
      <div className="answersheet-modal">
        <table className="faculty-signing">
          <thead>
            <tr>
              <th>No.</th>
              <th>Question</th>
              <th>Marks</th>
            </tr>
          </thead>
          <tbody>
            {answerSheetData.length > 0 ? (
              answerSheetData.map(
                (data: IExamPaperQuestion, rowIndex: number) => {
                  let obtainedMarks = null;
                  if (
                    data?.examPaperStudentAnswers &&
                    data.examPaperStudentAnswers.length > 0
                  ) {
                    obtainedMarks =
                      data?.examPaperStudentAnswers[0]?.marks || 0;
                  }
                  const totalMarks = data.marks || 0;

                  return (
                    <tr key={data.id}>
                      <td align="center">
                        <span style={{ display: "inline-block" }}>
                          {rowIndex + 1}
                        </span>
                      </td>
                      <td>
                        <div>
                          <h5
                            style={{ margin: 0 }}
                          >{`${data.question} (${data.question_type})`}</h5>
                          {data.question_type === "MCQ" && (
                            <ul
                              style={{
                                paddingLeft: "10px",
                                margin: "10px 0",
                                listStyleType: "none",
                              }}
                            >
                              {data.examPaperQuestionAnswers?.map(
                                (answer, index: number) => (
                                  <li
                                    key={answer.id}
                                    style={{ marginBottom: "5px" }}
                                  >
                                    <span>{index + 1}. </span>
                                    <span className={answer.is_right ? "correct-answer" : ""}>
                                      {answer.option}
                                    </span>
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                          <div style={{ marginTop: "10px" }}>
                            <p style={{ margin: 0 }}>
                              <Button
                                type="primary"
                                style={{
                                  padding: "0 8px",
                                }}
                                onClick={() => {
                                  setDescriptionData(
                                    data.description
                                      ? data.description
                                      : "No Description"
                                  );
                                  setShowModel(true);
                                }}
                              >
                                Description
                              </Button>
                              {/* <strong>Description: </strong>
                                  <div dangerouslySetInnerHTML={{ __html: data.description ? data.description : "No Description" }}
                                  /> */}
                            </p>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <p style={{ margin: 0 }}>
                              <strong>Answer: </strong>
                              {data.examPaperStudentAnswers &&
                                data.examPaperStudentAnswers.length > 0 ? (
                                data.examPaperStudentAnswers.map(
                                  (studentAnswer, index: number) => {
                                    const mcqAnswer =
                                      data.examPaperQuestionAnswers &&
                                      data.examPaperQuestionAnswers.find(
                                        (examPaperQuestionAnswer) =>
                                          examPaperQuestionAnswer.id ===
                                          studentAnswer?.exam_paper_question_answer_id
                                      );

                                    const answerContent =
                                      data.question_type === "MCQ"
                                        ? mcqAnswer?.option || "No Answer"
                                        : studentAnswer.answer || "No Answer";

                                    return (
                                      <span key={index}>
                                        {toLink(answerContent)}
                                      </span>
                                    );
                                  }
                                )
                              ) : (
                                <span>No Answer</span>
                              )}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td align="center" style={{ textAlign: "center" }}>
                        <div
                          className="marks gx-align-items-center"
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <span className="gx-align-items-center">
                            {obtainedMarks != null ? obtainedMarks : "-"}
                          </span>
                          / {totalMarks}
                        </div>
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr key={"none"}>
                <td colSpan={8} style={{ border: "none" }}>
                  <div>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <ModalComponent
        className="absent-remark-form admission-filter"
        title={`Description`}
        showModal={showModel}
        onCancel={() => setShowModel(false)}
        footer={false}
        component={
          <div
            dangerouslySetInnerHTML={{
              __html: descriptionData,
            }}
          />
        }
      />
    </>
  );
};
