import {
  Card,
  Col,
  Layout,
  Menu,
  Row,
  Typography,
  Button,
  Radio,
  RadioChangeEvent,
  Statistic,
  Modal,
  Alert,
  Upload,
  message,
  Form,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IAnswer, IExamPaperQuestion } from "src/services/exam/exam.model";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import {
  AddExamPaperStudentAnswer,
  getExamPaperDetailsByExamPaperId,
  submitExamPaperStudentAnswer,
} from "src/state/exam/exam.action";
import { studentExamSelector } from "src/state/exam/exam.reducer";
import { AnswerType, TopicType } from "src/utils/constants/constant";
import { suffleArray } from "src/utils/helperFunction";
import { UploadOutlined } from "@ant-design/icons";
const { Header, Sider, Content } = Layout;
const { Title } = Typography;
const { Countdown } = Statistic;

const ExamDashboard = () => {
  const location = useLocation();
  const { data } = location.state;
  const dispatch = useDispatch<AppDispatch>();
  const studentExamState = useAppSelector(studentExamSelector);
  const [examQuestionsAndAnswerData, setExamQuestionsAndAnswerData] = useState<
    IExamPaperQuestion[]
  >([]);
  const [currentQuestion, setCurrentQuestion] = useState<IExamPaperQuestion>();
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const [deadline, setDeadline] = useState<any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      const examParamData = {
        exam_paper_id: data.id,
        admission_id: data.admission_id,
      };
      dispatch(getExamPaperDetailsByExamPaperId(examParamData));
      const duration = data.exam_hour_time;
      if (duration) {
        const [hours, minutes, seconds] = duration?.split(".").map(Number);
        const durationInMinutes =
          hours * 60 + (minutes || 0) + (seconds || 0) / 60;

        const currentTime = moment();
        const examEndTime = currentTime.add(durationInMinutes, "minutes");
        setDeadline(examEndTime);
      }
    }
  }, [data]);
  useEffect(() => {
    if (window.performance) {
      const navigationEntries = performance.getEntriesByType(
        "navigation"
      ) as PerformanceNavigationTiming[];
      if (navigationEntries.length > 0) {
        const navigationType = navigationEntries[0].type;
        if (navigationType === "reload") {
          handleSubmit();
          window.close();
        }
      }
    }
  }, []);
  useEffect(() => {
    if (studentExamState.examQuestionsAndAnswerData.data.length > 0) {
      // Shuffle the fetched data
      const shuffledData = suffleArray([
        ...studentExamState.examQuestionsAndAnswerData.data,
      ]);
      // const shuffledData = studentExamState.examQuestionsAndAnswerData.data;
      setExamQuestionsAndAnswerData(shuffledData);
      setCurrentQuestion(shuffledData[0]);
    }
  }, [studentExamState.examQuestionsAndAnswerData.data]);

  useEffect(() => {
    if (
      studentExamState.submitQuestionAnswerStudentData.data
        .examAttendanceUpdated === true
    ) {
      navigate("/exam-portal/thank-you");
    }
  }, [studentExamState.submitQuestionAnswerStudentData.data]);

  const handleQuestionClick = (id: number) => {
    const question = examQuestionsAndAnswerData.find((q) => q.id === id);
    if (question) {
      setCurrentQuestion(question);
    }
  };

  const handleOptionChange = (e: RadioChangeEvent) => {
    handleChangeSubmit(e.target.value);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (currentQuestion) {
      const { id: questionId } = currentQuestion;
      const newAnswer = {
        questionId,
        answer: e.target.value,
        isValidate: validateUrl(e.target.value),
      };
      setAnswers((prevAnswers: any) => {
        const updatedAnswers = prevAnswers.filter(
          (answer: any) => answer.questionId !== questionId
        );
        return [...updatedAnswers, newAnswer];
      });
    }
  };

  const urlPattern = new RegExp(
    "^" +
      "(?:(?:https?|http)://)" +
      "(?:\\S+(?::\\S*)?@)?" +
      "(?:" +
      "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
      "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
      "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
      "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
      "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
      "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
      "|" +
      "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
      "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
      "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
      ")" +
      "(?::\\d{2,5})?" +
      "(?:/\\S*)?" +
      "$",
    "i"
  );
  const validateUrl = (value: string) => {
    if (value === "") return true;
    return urlPattern.test(value); // Validate URL
  };

  const handleChangeSubmit = (value: string | number) => {
    if (currentQuestion) {
      const { id: questionId } = currentQuestion;
      const newAnswer = {
        questionId,
        answer: value,
        isValidate: validateUrl(value as string),
        answer_type: currentQuestion.answer_type,
      };
      setAnswers((prevAnswers: any) => {
        const updatedAnswers = prevAnswers.filter(
          (answer: any) => answer.questionId !== questionId
        );
        return [...updatedAnswers, newAnswer];
      });
      submitExamAnswerOnNextQuestion(newAnswer);
    }
  };
  const submitExamAnswerOnNextQuestion = (answerData: IAnswer) => {
    if (currentQuestion) {
      if (answerData) {
        let studentExamAmberdata = {};
        if (
          (data.exam_type === TopicType.EXAM_PRACTICAL &&
            answerData?.answer_type === AnswerType.UPLOAD_FILE) ||
          ((answerData?.answer || answerData?.answer === "") &&
            answerData?.isValidate)
        ) {
          studentExamAmberdata = {
            exam_paper_student_id: data?.exam_paper_student_id,
            exam_paper_question_id: currentQuestion.id,
            answer:
              answerData?.answer_type === AnswerType.URL
                ? answerData.answer
                : "",
            file: answerData.file,
          };
        } else if (data.exam_type === TopicType.EXAM_THEORY) {
          studentExamAmberdata = {
            exam_paper_student_id: data?.exam_paper_student_id,
            exam_paper_question_id: currentQuestion.id,
            exam_paper_question_answer_id: answerData.answer,
          };
        }
        if (Object.keys(studentExamAmberdata).length > 0) {
          dispatch(
            AddExamPaperStudentAnswer({
              ...studentExamAmberdata,
              exam_paper_question_id: currentQuestion?.id,
            })
          );
        }
      }
    }
  };
  const handleNext = async () => {
    const currentIndex = examQuestionsAndAnswerData.findIndex(
      (q) => q.id === currentQuestion?.id
    );
    if (
      currentIndex !== -1 &&
      currentIndex < examQuestionsAndAnswerData.length - 1
    ) {
      const nextQuestion = examQuestionsAndAnswerData[currentIndex + 1];
      setCurrentQuestion(nextQuestion);
    }
  };

  const handlePrevious = () => {
    const currentIndex = examQuestionsAndAnswerData.findIndex(
      (q) => q.id === currentQuestion?.id
    );
    if (currentIndex > 0) {
      const prevQuestion = examQuestionsAndAnswerData[currentIndex - 1];
      setCurrentQuestion(prevQuestion);
    }
  };

  const handleSubmit = () => {
    const studentExamAmberdata = {
      exam_paper_student_id: data?.exam_paper_student_id,
      exam_student_data: answers,
      exam_paper_id: data.id,
    };
    dispatch(submitExamPaperStudentAnswer(studentExamAmberdata));
  };
  useEffect(() => {
    if (deadline) {
      const interval = setInterval(() => {
        const currentTime = moment();
        const remainingTime = deadline.diff(currentTime);
        if (remainingTime <= 0) {
          clearInterval(interval);
          handleSubmit();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [answers, deadline]);

  const uploadFile = (values: any) => {
    const isZip =
      values.type === "application/zip" || values.name.endsWith(".zip");

    if (!isZip) {
      message.error("You can only upload ZIP files!");
      return Upload.LIST_IGNORE; // Ignore the file
    }

    const isLt300MB = values.size / 1024 / 1024 < 300;
    if (!isLt300MB) {
      message.error("File must be smaller than 300MB!");
      return Upload.LIST_IGNORE;
    }

    if (currentQuestion && values) {
      const file = values;
      const questionId = currentQuestion.id;
      const newAnswer: IAnswer = {
        questionId,
        file: file,
        answer_type: currentQuestion.answer_type,
      };
      setAnswers((prevAnswers: any) => {
        const updatedAnswers = prevAnswers.filter(
          (answer: any) => answer.questionId !== questionId
        );
        return [...updatedAnswers, newAnswer];
      });
      submitExamAnswerOnNextQuestion(newAnswer);
    }
  };
  const isValidateUrl = () => {
    if (currentQuestion) {
      const answer = answers.find(
        (answer) => answer.questionId === currentQuestion.id
      );
      if (answer?.isValidate === undefined) {
        return true;
      }
      return answer?.isValidate;
    }
  };
  const getSelectedAnswer = () => {
    if (!currentQuestion) return;

    // Check if answer exists in the answers array first.
    const answerFromAnswers = answers.find(
      (answer) => answer.questionId === currentQuestion.id
    );
    if (answerFromAnswers) {
      if (currentQuestion?.answer_type === AnswerType.UPLOAD_FILE) {
        const fileData = answerFromAnswers.file as File;
        return fileData?.name;
      } else {
        return answerFromAnswers.answer;
      }
    }

    // Otherwise, check examPaperStudentAnswers.
    const studentAnswer = currentQuestion?.examPaperStudentAnswers.find(
      (ans) =>
        ans.exam_paper_question_id === currentQuestion.id &&
        ans.exam_paper_student_id === data?.exam_paper_student_id
    );

    // Return based on exam type.
    return data?.exam_type === TopicType.EXAM_PRACTICAL
      ? studentAnswer?.answer
      : studentAnswer?.exam_paper_question_answer_id;
  };

  return studentExamState.examQuestionsAndAnswerData.data.length > 0 ? (
    <Layout className="exam-dashboard-layout">
      <Sider width={160} className="custom-scrollbar">
        <Menu
          mode="inline"
          selectedKeys={[String(currentQuestion?.id)]}
          className="exam-menu"
        >
          {examQuestionsAndAnswerData &&
            examQuestionsAndAnswerData?.map((question, index) => (
              <Menu.Item
                key={question.id}
                onClick={() => handleQuestionClick(question.id)}
              >{`Question ${index + 1}`}</Menu.Item>
            ))}
        </Menu>
      </Sider>
      <Layout>
        <Content className="exam-content">
          <Card>
            <Title level={3}>{currentQuestion?.question}</Title>
            <div
              dangerouslySetInnerHTML={{
                __html: currentQuestion?.description ?? "",
              }}
            />

            {data?.exam_type === TopicType.EXAM_PRACTICAL ? (
              currentQuestion?.answer_type === AnswerType.UPLOAD_FILE ? (
                <>
                  <Upload
                    beforeUpload={uploadFile}
                    showUploadList={false}
                    maxCount={1}
                    accept={
                      "application/zip,application/x-zip-compressed,application/x-zip,application/x-rar-compressed,application/octet-stream,application/x-7z-compressed"
                    }
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>

                  <div>
                    <p>{getSelectedAnswer()}</p>
                  </div>
                </>
              ) : (
                <>
                  <TextArea
                    value={getSelectedAnswer()}
                    onChange={handleTextChange}
                    onBlur={(e) => handleChangeSubmit(e.target.value)}
                    rows={4}
                  />
                  {!isValidateUrl() && (
                    <p className="error-message">Invalid URL</p>
                  )}{" "}
                </>
              )
            ) : (
              <>
                <Radio.Group
                  onChange={handleOptionChange}
                  value={getSelectedAnswer()}
                >
                  {currentQuestion?.examPaperQuestionAnswers?.map(
                    (option, index) => (
                      <Radio
                        key={`${option.id}_${index}`}
                        value={option.id}
                        className="exam-options"
                      >
                        {option.option}
                      </Radio>
                    )
                  )}
                </Radio.Group>
              </>
            )}

            <div className="exam-buttons">
              <Button
                onClick={handlePrevious}
                disabled={
                  examQuestionsAndAnswerData.findIndex(
                    (q) => q.id === currentQuestion?.id
                  ) === 0
                }
              >
                Previous
              </Button>
              <Button
                type="primary"
                onClick={handleNext}
                disabled={
                  examQuestionsAndAnswerData.findIndex(
                    (q) => q.id === currentQuestion?.id
                  ) ===
                  examQuestionsAndAnswerData.length - 1
                }
              >
                Next
              </Button>
            </div>
          </Card>
        </Content>
      </Layout>
      <Sider width={300} className="exam-sider">
        <Card>
          <Countdown
            title="Remaining Time"
            value={deadline}
            className="exam-countdown"
          />
          <Button
            className="exam-submit-btn"
            onClick={() => {
              Modal.confirm({
                title: "Are you sure you want to Submit?",
                okText: "Yes",
                cancelText: "No",
                onOk() {
                  handleSubmit();
                },
              });
            }}
          >
            Submit
          </Button>
        </Card>
        <Row gutter={[24, 16]}>
          {examQuestionsAndAnswerData.map((q, index) => {
            const isAnswered = () => {
              const answereData = answers.find(
                (answer) => answer.questionId === q.id
              );
              if (data?.exam_type === TopicType.EXAM_PRACTICAL) {
                return answereData
                  ? answereData.answer !== "" && answereData.answer !== null
                  : q.examPaperStudentAnswers.length > 0 &&
                    q.examPaperStudentAnswers[0].exam_paper_student_id ===
                      data?.exam_paper_student_id
                  ? q.examPaperStudentAnswers[0].answer !== "" &&
                    q.examPaperStudentAnswers[0].answer !== null
                  : false;
              }
              return answereData &&
                answereData.answer !== "" &&
                answereData.answer !== null
                ? true
                : q.examPaperStudentAnswers.length > 0 &&
                  q.examPaperStudentAnswers[0].exam_paper_student_id ===
                    data?.exam_paper_student_id
                ? true
                : false;
            };

            return (
              <Col span={4} key={q.id}>
                <div
                  className={`circle-indicator ${
                    currentQuestion?.id === q.id ? "selected" : ""
                  }`}
                  style={{
                    backgroundColor: isAnswered() ? "#52c41a" : "#f5222d",
                  }}
                  onClick={() => handleQuestionClick(q.id)}
                >
                  {index + 1}
                </div>
              </Col>
            );
          })}
        </Row>
      </Sider>
    </Layout>
  ) : (
    <Layout className="exam-dashboard-layout">
      <Content className="exam-content">
        <div className="exam-no-questions">
          <Alert message="No questions found." type="warning" showIcon />
        </div>
      </Content>
    </Layout>
  );
};

export default ExamDashboard;
